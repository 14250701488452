<template>
  <div>
    <div class="page-section-alt">
      <banner :title="meta.title" />

      <v-container>
        <v-row>
          <v-col class="pb-12 mb-12">
            <p>
              Kadın Hekimler Eğitime Destek Vakfı (KAHEV) tarafından yürütülen
              www.kahev.org veya www.kahev.org.tr alan adlı siteye erişiminizden
              ve siteyi kullanmanızdan önce bu şartları dikkatle okuyunuz.
              Siteye erişmekle ve siteyi kullanmayı devam etmekle, aşağıda
              belirtilen şartlar ve hükümlerle bağlı olmayı kabul etmektesiniz.
              Bu şartlarda yapılan değişiklikler web sitesinde güncellenmesiyle
              derhal yürürlük kazanır. Kullanım şartlarını periyodik olarak
              gözden geçirmeniz gerekmektedir.
            </p>

            <ol>
              <li>
                “www.kahev.org” ve “www.kahev.org.tr” internet sitesi, alan adı,
                tasarımı, “KAHEV” markası ve logosu ve bunlara ilişkin olarak
                KAHEV tarafından oluşturulan her türlü marka, tasarım, logo ve
                içerik KAHEV mülkiyetindedir. Bunlara ilişkin telif hakkı
                ve/veya diğer fikri mülkiyet hakları ilgili kanunlarca
                korunmakta olup, bu haklar web sitesi kullanıcıları tarafından
                izinsiz kullanılamaz, iktisap edilemez ve değiştirilemez. Bu web
                sitesinde adı geçen başkaca şirketler ve ürünleri sahiplerinin
                ticari markalarıdır ve ayrıca fikri mülkiyet hakları kapsamında
                korunmaktadır.
              </li>
              <li>
                Kullanıcı, web sitesinde yer alan ürünleri, bilgileri,
                resimleri, metinleri, ikonları ve her türlü veriyi kısmen ya da
                tamamen çoğaltamayacağını, kopyalamayacağını, dağıtmayacağı,
                işlemeyeceğini kabul ve taahhüt etmektedir.
              </li>
              <li>
                Kullanıcı, Web sitesini kullanırken yasal mevzuat hükümlerine
                riayet etmeyi ve bunları ihlal etmemeyi baştan kabul ve taahhüt
                eder. Aksi takdirde, doğacak tüm hukuki ve cezai yükümlülükler
                tamamen ve münhasıran Kullanıcıyı bağlayacaktır.
              </li>
              <li>
                Kullanıcı, Web Sitesini hiçbir şekilde kamu düzenini bozucu,
                genel ahlaka aykırı, başkalarını rahatsız ve taciz edici
                şekilde, yasalara aykırı bir amaç için, başkalarının fikri ve
                telif haklarına tecavüz edecek şekilde kullanamaz. Ayrıca
                Kullanıcı, başkalarının hizmetleri kullanmasını önleyici veya
                zorlaştırıcı faaliyet (spam, virus, truva atı, vb.) ve
                işlemlerde bulunamaz.
              </li>
              <li>
                Web Sitesi’nde Kullanıcılar tarafından beyan edilen, yazılan,
                kullanılan fikir ve düşünceler, tamamen kendi kişisel
                görüşleridir ve görüş sahibini bağlar. Bu görüş ve düşüncelerin
                KAHEV ile hiçbir ilgi ve bağlantısı yoktur. Kullanıcının beyan
                edeceği fikir ve görüşler nedeniyle üçüncü kişilerin
                uğrayabileceği zararlardan ve üçüncü kişilerin beyan edeceği
                fikir ve görüşler nedeniyle Kullanıcının uğrayabileceği
                zararlardan dolayı KAHEV’in herhangi bir sorumluluğu
                bulunmamaktadır.
              </li>
              <li>
                KAHEV, Web Sitesi’nin siber güvenliğinin sağlanması ile ilgili
                olarak gerekli ve güncel tedbirleri almaktadır. KAHEV, web
                sitesini kullanan kişilerin şahsi bilgisayar, telefon, tablet
                vb. her türlü cihazının kullanımı sırasında, Kullanıcıdan
                kaynaklanan nedenlerle Kullanıcının yazılım ve verilerine
                gelebilecek zararlardan dolayı sorumlu olmayacaktır.
              </li>
              <li>
                Kullanıcı, Web Sitesi üzerinde orantısız derecede büyük
                yüklemeler yaratacak ya da teknik işleyişe zarar verecek
                faaliyetlerde bulunmayacağını, KAHEV’in önceden yazılı izni
                alınmaksızın Web Sitesi üzerinde otomatik program, robot, web
                crawler, örümcek, veri madenciliği ve veri taraması gibi
                yazılımlar veya sistemler kullanmayacağını ve bu şekilde Web
                Sitesi’nden herhangi bir içeriğin tamamının veya bir kısmının
                izinsiz kopyalanarak yayınlamayacağını ve kullanmayacağını kabul
                ve taahhüt eder.
              </li>
              <li>
                KAHEV tarafından Web Sitesi’nin iyileştirilmesi,
                geliştirilmesine yönelik olarak ve/veya yasal mevzuat
                çerçevesinde siteye erişmek için kullanılan İnternet servis
                sağlayıcısının adı ve İnternet Protokol (IP) adresi, Siteye
                erişilen tarih ve saat, sitede bulunulan sırada erişilen
                sayfalar ve siteye doğrudan bağlanılmasını sağlayan Web
                sitesinin İnternet adresi gibi birtakım bilgiler toplanabilir.
              </li>
              <li>
                KAHEV, web sitesi kullanıcılarına daha iyi hizmet sunmak,
                ürünlerini ve hizmetlerini iyileştirmek, sitenin kullanımını
                kolaylaştırmak için Kullanıcıların özel tercihlerine ve ilgi
                alanlarına yönelik çalışmalarda Kullanıcının açık rızası ya da
                diğer hukuka uygunluk sebeplerine bağlı olarak Kullanıcının
                kişisel bilgilerini kullanabilir. Bununla ilgili olarak web
                sitesinde Kişisel Verilerin Korunması Politikası, Çerez
                Politikası, Aydınlatma Metinleri ve Açık Rıza Formları Web
                Sitesi’nde yayınlanmaktadır.
              </li>
              <li>
                KAHEV, sitenin içeriğini dilediği zaman değiştirme,
                Kullanıcılara sağlanan herhangi bir hizmeti değiştirme ya da
                sona erdirme veya web sitesinde kayıtlı Kullanıcı ya da Üye
                bilgi ve verilerini silme hakkını saklı tutar.
              </li>
              <li>
                KAHEV, Web Sitesi Kullanım Şartlarını hiçbir şekilde ve surette
                ön ihbara ve/veya ihtara gerek kalmaksızın her zaman
                değiştirebilir, güncelleyebilir veya iptal edebilir.
                Değiştirilen, güncellenen ya da yürürlükten kaldırılan her bir
                hüküm, yayın tarihinde tüm Kullanıcılar bakımından hüküm ifade
                edecektir.
              </li>
            </ol>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import Banner from "./Banner.vue";

export default {
  components: {
    Banner
  },

  data() {
    return {
      meta: {
        title: "KAHEV Kullanım Şartları"
      }
    };
  },

  metaInfo() {
    const title = this.meta.title;

    return {
      title: title
    };
  }
};
</script>

<style lang="scss" scoped>
ol {
  li {
    margin-bottom: 18px;
  }
}
</style>
